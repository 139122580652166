import React, {useState, useEffect} from "react";
import { Button, Divider, Grid, Modal, Box, Typography, TextField, Card, CardContent } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';

import ServiceTabs from '../../../components/tabs/ServiceTabs';
import ServiceStepper from '../../../components/stepper/ServiceStepper';
import dataJSON from "../../../data/company.json";
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

import { ReactComponent as SVG_1 } from "../../../assets/idea.svg";
import { ReactComponent as SVG_2 } from "../../../assets/target.svg";
import { ReactComponent as SVG_3 } from "../../../assets/team.svg";
import { ReactComponent as SVG_4 } from "../../../assets/building.svg";

import { ReactComponent as SVG_JIRA } from "../../../assets/svg/platforms/jira.svg";
import { ReactComponent as SVG_POWERBI } from "../../../assets/svg/platforms/powerbi.svg";
import { ReactComponent as SVG_SALESFORCE } from "../../../assets/svg/platforms/salesforce.svg";
import { ReactComponent as SVG_LOOKER } from "../../../assets/svg/platforms/looker.svg";
import { ReactComponent as SVG_TABLEAU } from "../../../assets/svg/platforms/tableau.svg";
import { ReactComponent as SVG_SERVICENOW } from "../../../assets/svg/platforms/servicenow.svg";
import { ReactComponent as SVG_CONFLUENCE } from "../../../assets/svg/platforms/confluence.svg";
import { ReactComponent as SVG_BITBUCKET } from "../../../assets/svg/platforms/bitbucket.svg";
import { ReactComponent as SVG_GITHUB } from "../../../assets/svg/platforms/github.svg";
import { ReactComponent as SVG_GITLAB } from "../../../assets/svg/platforms/gitlab.svg";

import { ReactComponent as SVG_AWS } from "../../../assets/svg/clouds/aws.svg";
import { ReactComponent as SVG_GCP } from "../../../assets/svg/clouds/gcp.svg";
import { ReactComponent as SVG_AZURE } from "../../../assets/svg/clouds/azure.svg";

import { ReactComponent as SVG_DOCKER } from "../../../assets/svg/devops/docker.svg";
import { ReactComponent as SVG_KUBERNETES } from "../../../assets/svg/devops/kubernetes.svg";
import { ReactComponent as SVG_OPENSHIFT } from "../../../assets/svg/devops/openshift.svg";
import { ReactComponent as SVG_MESOS } from "../../../assets/svg/devops/mesos.svg";
import { ReactComponent as SVG_ANSIBLE } from "../../../assets/svg/devops/ansible.svg";
import { ReactComponent as SVG_CHEF } from "../../../assets/svg/devops/chef.svg";
import { ReactComponent as SVG_TERRAFORM } from "../../../assets/svg/devops/terraform.svg";
import { ReactComponent as SVG_JENKINS } from "../../../assets/svg/devops/jenkins.svg";
import { ReactComponent as SVG_TRAVIS } from "../../../assets/svg/devops/travis.svg";
import { ReactComponent as SVG_HELM } from "../../../assets/svg/devops/helm.svg";
import { ReactComponent as SVG_ELASTICSEARCH } from "../../../assets/svg/devops/elasticsearch.svg";
import { ReactComponent as SVG_NAGIOS } from "../../../assets/svg/devops/nagios.svg";
import { ReactComponent as SVG_PROMETHEUS } from "../../../assets/svg/devops/prometheus.svg";
import { ReactComponent as SVG_GRAFANA } from "../../../assets/svg/devops/grafana.svg";



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: "flex",
    flexDirection: "column",
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    maxWidth: '600px',
    maxHeight: '600px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow: "hidden",
    overflowY: "auto",
    p: 4,
};

const Home = () => {
    const theme = useTheme();
    const history = useHistory();
    const mission_statement = dataJSON.mission.statement;
    const mission_description = dataJSON.mission.description;
    const MAX_ARTICLES = 4;
    const [open, setOpen] = useState(false);
    const [articles, setArticles] = useState([]);

    const rssFeed = "https://rss.app/feeds/v1.1/trthaibc5F29lJFs.json";

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const loadArticles = async () => {
        fetch(rssFeed, { headers: { Accept: "application/json"} })
        .then((res) => res.json())
        .then((data) => data.items.filter((item) => item.title.length > 0))
        .then((newArticles) => newArticles.slice(0, MAX_ARTICLES))
        .then((articles) => setArticles(articles))
        .catch((error) => console.log(error));
    };

    useEffect(() => {
        loadArticles();
    }, [MAX_ARTICLES]);

    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.grey[100], padding: 0, minHeight: '500px', marginTop: '-10px', paddingTop: '24px', paddingBottom: '24px', background: 'radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%)'}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className="row mt-4 mb-4 justify-content-md-center">
                                <div className="col col-12 col-sm-12 col-md-10 col-lg-7 col-xl-7 d-block d-lg-none">
                                    <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'center', verticalAlign: 'middle', padding: '10px', paddingTop: '40px'}}>
                                        <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                            <div style={{ textAlign: 'center', padding: '0px', marginTop: '0px', marginBottom: '40px'}}>
                                                <SVG_1 width="300px" height="auto"/>
                                            </div>
                                        </div>
                                        <p className="h2 mb-4 font-weight-normal" style={{fontFamily: 'inherit', fontSize: '40px'}}>Leveraging human capital to gain a competitive edge</p>
                                        <p className="h4 mb-4 font-weight-light" style={{fontFamily: 'inherit'}}>Enhancing performance through people</p>
                                        <p className="h5 mb-4 font-weight-light" style={{fontFamily: 'inherit'}}>At Ops51, we are committed to delivering exceptional technology services tailored to meet the unique needs and challenges of our clients. With a steadfast focus on excellence, innovation, and collaboration, we strive to drive sustainable growth for businesses across diverse industries.</p>
                                        <div style={{ textAlign: 'inherit' }}>
                                            <Button className="mb-4" variant="contained" style={{padding: '10px', fontSize: '18px', textTransform: 'none', width: '160px'}} onClick={handleOpen}>
                                                Get Started
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-none d-lg-block">
                                    <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'right', verticalAlign: 'middle', padding: '10px', paddingTop: '60px'}}>
                                        <p className="h1 mb-4 font-weight-normal" style={{fontFamily: 'inherit', fontSize: '48px'}}>Leveraging human capital to gain a competitive edge</p>
                                        <p className="h3 mb-4 font-weight-light" style={{fontFamily: 'inherit'}}>Enhancing performance through people</p>
                                        <p className="h5 mb-4 font-weight-light" style={{fontFamily: 'inherit'}}>At Ops51, we are committed to delivering exceptional technology services tailored to meet the unique needs and challenges of our clients. With a steadfast focus on excellence, innovation, and collaboration, we strive to drive sustainable growth for businesses across diverse industries.</p>
                                        <div style={{ textAlign: 'inherit' }}>
                                            <Button className="mb-4" variant="contained" style={{padding: '10px', fontSize: '18px', textTransform: 'none', width: '160px'}} onClick={handleOpen}>
                                                Get Started
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 d-none d-lg-block">
                                    <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                        <div style={{ textAlign: 'center', padding: '0px', marginTop: '24px'}}>
                                            <SVG_1 width="400px" height="400px"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.common.black, padding: 0}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className='row mt-0 mb-0 justify-content-md-center' style={{textAlign: 'center', verticalAlign: 'middle', padding: '10px', paddingTop: '40px', paddingBottom: '40px', minHeight: '500px'}}>
                                <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div className='mb-0 font-weight-light' style={{textAlign: 'center'}}>
                                        <div style={{ textAlign: 'center', padding: '0px'}}>
                                            <SVG_2 width="120px" height="120px"/>
                                            <p className="h6 mb-0 font-weight-normal" style={{fontFamily: 'inherit', fontSize: '16px', marginTop: '-20px', color: theme.palette.grey[300]}}>Our Mission</p>
                                        </div>
                                    </div>
                                    <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'center', verticalAlign: 'middle', padding: '10px', paddingTop: '24px', paddingBottom: '40px'}}>
                                        <p className="h2 mb-4 font-weight-normal" style={{fontFamily: 'inherit', fontSize: '36px', color: theme.palette.grey[300]}}>{mission_statement}</p>
                                        <p className="h4 mb-4 font-weight-light" style={{fontFamily: 'inherit', display: 'inline-block', maxWidth: '700px', color: theme.palette.grey[300]}}>{mission_description}</p>
                                        <div style={{ textAlign: 'inherit' }}>
                                            <Button className="mb-4" variant="outlined" style={{padding: '10px', fontSize: '18px', textTransform: 'none', width: '160px', borderColor: theme.palette.grey[300], color: theme.palette.grey[300]}} onClick={(e) => { 
                                                e.preventDefault();
                                                history.push('/about/mission')
                                            }}>
                                                Learn more
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.grey[100], padding: 0, paddingTop: '24px', paddingBottom: '24px'}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-11 col-xl-11">
                            <div className="row mt-4 mb-4 justify-content-md-center">
                                <div className="col col-12 col-sm-12 col-md-10 col-lg-7 col-xl-7 d-block d-lg-none">
                                    <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '32px'}}>
                                        <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                            <div style={{ textAlign: 'left', padding: '0px', marginTop: '0px', marginBottom: '32px'}}>
                                                <SVG_4 width="240px" height="auto"/>
                                            </div>
                                        </div>
                                        <p className="h2 mb-4 font-weight-normal" style={{fontFamily: 'inherit', fontSize: '36px', color: theme.palette.grey[900]}}>Building the Stack</p>
                                        <p className="h4 mb-4 font-weight-light" style={{fontFamily: 'inherit', display: 'inline-block', maxWidth: '700px', color: theme.palette.grey[900]}}>Through our strategy-driven approach we translate your business needs into scalable data solutions, ensuring value delivery beyond the initial implementation.</p>
                                        <div style={{ textAlign: 'inherit' }}>
                                            <Button className="ml-2 mb-4" variant="outlined" style={{padding: '10px', paddingLeft: '16px', paddingRight: '16px', fontSize: '14px', textTransform: 'none' }} onClick={(e) => { 
                                                e.preventDefault();
                                                history.push('/services')
                                            }}>
                                                View&nbsp;our&nbsp;services&nbsp;<ArrowCircleRightOutlinedIcon fontSize="small"/>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 d-none d-lg-block">
                                    <div className='mb-0 font-weight-light' style={{textAlign: 'right'}}>
                                        <div style={{ textAlign: 'center', padding: '0px'}}>
                                            <SVG_4 width="420px" height="auto"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-none d-lg-block">
                                    <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '32px'}}>
                                        <p className="h2 mb-4 font-weight-normal" style={{fontFamily: 'inherit', fontSize: '36px', color: theme.palette.grey[900]}}>Building the Stack</p>
                                        <p className="h4 mb-4 font-weight-light" style={{fontFamily: 'inherit', display: 'inline-block', maxWidth: '700px', color: theme.palette.grey[900]}}>Through our strategy-driven approach we translate your business needs into scalable data solutions, ensuring value delivery beyond the initial implementation.</p>
                                        <div style={{ textAlign: 'inherit' }}>
                                            <Button className="mb-4" variant="outlined" style={{padding: '10px', paddingLeft: '16px', paddingRight: '16px',  fontSize: '14px', textTransform: 'none'}} onClick={(e) => { 
                                                e.preventDefault();
                                                history.push('/services')
                                            }}>
                                                View&nbsp;our&nbsp;services&nbsp;<ArrowCircleRightOutlinedIcon fontSize="small"/>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.grey[100], padding: 0}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-11 col-xl-11">
                            <hr/>
                            <div style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingLeft: '16px', paddingTop: '24px', paddingBottom: '24px', marginTop: '40px'}}>
                                <p className="h1 font-weight-normal" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.common.black}}>Platforms and Technologies we work with</p>
                                <Divider className="mb-0" sx={{ bgcolor: "info.light", width: '100px', borderWidth: '2px' }}/>
                            </div>
                            <div style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingLeft: '16px', paddingTop: '0px', paddingBottom: '40px', marginTop: '0px'}}>
                                <div className='row mt-0 mb-0 justify-content-md-center' style={{textAlign: 'center', verticalAlign: 'middle'}}>
                                    <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <Card variant="outlined" sx={{ bgcolor: 'transparent', borderRadius: '0px', borderWidth: '0px' }}>
                                            <CardContent sx={{ p: 1 }}>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <Box sx={{ display: 'block', pb: 2, width: '100%' }}>
                                                            <div style={{ textAlign: 'left', width: '100%', paddingBottom: '8px'}}>
                                                                <p className="h4" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.info.main, fontWeight: 400}}>Platforms</p>
                                                            </div>
                                                            <div style={{ textAlign: 'left', width: '100%' }}>
                                                                <p className="h6" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.info, textTransform: 'uppercase', fontWeight: 400}}>Business</p>
                                                            </div>
                                                            <div style={{ textAlign: 'left', width: '100%', paddingBottom: '16px'}}>
                                                                <Grid container spacing={0} sx={{ display: "flex" }}>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '8px', paddingRight: '8px'}}>
                                                                            <SVG_SALESFORCE width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '16px', paddingRight: '16px'}}>
                                                                            <SVG_LOOKER width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '8px', paddingRight: '8px'}}>
                                                                            <SVG_TABLEAU width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '16px', paddingRight: '16px'}}>
                                                                            <SVG_POWERBI width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                            <div style={{ textAlign: 'left', width: '100%' }}>
                                                                <p className="h6" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.info, textTransform: 'uppercase', fontWeight: 400}}>Collaboration</p>
                                                            </div>
                                                            <div style={{ textAlign: 'left', width: '100%', paddingBottom: '16px'}}>
                                                                <Grid container spacing={0} sx={{ display: "flex" }}>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '8px', paddingRight: '8px'}}>
                                                                            <SVG_SERVICENOW width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '28px', paddingRight: '28px'}}>
                                                                            <SVG_JIRA width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '8px', paddingRight: '8px'}}>
                                                                            <SVG_CONFLUENCE width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                            <div style={{ textAlign: 'left', width: '100%' }}>
                                                                <p className="h6" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.info, textTransform: 'uppercase', fontWeight: 400}}>Version Control</p>
                                                            </div>
                                                            <div style={{ textAlign: 'left', width: '100%'}}>
                                                                <Grid container spacing={0} sx={{ display: "flex" }}>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '12px', paddingRight: '12px'}}>
                                                                            <SVG_BITBUCKET width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '8px', paddingRight: '8px'}}>
                                                                            <SVG_GITHUB width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '16px', paddingRight: '16px'}}>
                                                                            <SVG_GITLAB width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </Box>
                                                        <Box sx={{ display: 'block', pb: 2, width: '100%' }}>
                                                            <div style={{ textAlign: 'left', width: '100%', paddingBottom: '8px'}}>
                                                                <p className="h4" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.info.main, fontWeight: 400}}>Clouds</p>
                                                            </div>
                                                            <div style={{ textAlign: 'left', width: '100%'}}>
                                                                <Grid container spacing={0} sx={{ display: "flex" }}>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '8px', paddingRight: '8px'}}>
                                                                            <SVG_AWS width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '8px', paddingRight: '8px'}}>
                                                                            <SVG_AZURE width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '8px', paddingRight: '8px'}}>
                                                                            <SVG_GCP width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <Box sx={{ display: 'block', pb: 2, width: '100%' }}>
                                                            <div style={{ textAlign: 'left', width: '100%', paddingBottom: '8px'}}>
                                                                <p className="h4" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.info.main, fontWeight: 400}}>DevOps</p>
                                                            </div>
                                                            <div style={{ textAlign: 'left', width: '100%' }}>
                                                                <p className="h6" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.info, textTransform: 'uppercase', fontWeight: 400}}>Containerization</p>
                                                            </div>
                                                            <div style={{ textAlign: 'left', width: '100%', paddingBottom: '16px'}}>
                                                                <Grid container spacing={0} sx={{ display: "flex" }}>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '8px', paddingRight: '8px'}}>
                                                                            <SVG_DOCKER width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '8px', paddingRight: '8px'}}>
                                                                            <SVG_KUBERNETES width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '8px', paddingRight: '8px'}}>
                                                                            <SVG_OPENSHIFT width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '8px', paddingRight: '8px'}}>
                                                                            <SVG_MESOS width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                            <div style={{ textAlign: 'left', width: '100%' }}>
                                                                <p className="h6" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.info, textTransform: 'uppercase', fontWeight: 400}}>Automation</p>
                                                            </div>
                                                            <div style={{ textAlign: 'left', width: '100%', paddingBottom: '16px'}}>
                                                                <Grid container spacing={0} sx={{ display: "flex" }}>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '8px', paddingRight: '8px'}}>
                                                                            <SVG_ANSIBLE width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '8px', paddingRight: '8px'}}>
                                                                            <SVG_CHEF width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '8px', paddingRight: '8px'}}>
                                                                            <SVG_TERRAFORM width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                            <div style={{ textAlign: 'left', width: '100%' }}>
                                                                <p className="h6" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.info, textTransform: 'uppercase', fontWeight: 400}}>Package Manager</p>
                                                            </div>
                                                            <div style={{ textAlign: 'left', width: '100%', paddingBottom: '16px'}}>
                                                                <Grid container spacing={0} sx={{ display: "flex" }}>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px'}}>
                                                                            <SVG_HELM width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                            <div style={{ textAlign: 'left', width: '100%' }}>
                                                                <p className="h6" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.info, textTransform: 'uppercase', fontWeight: 400}}>CI/CD tools</p>
                                                            </div>
                                                            <div style={{ textAlign: 'left', width: '100%', paddingBottom: '16px'}}>
                                                                <Grid container spacing={0} sx={{ display: "flex" }}>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '8px', paddingRight: '8px'}}>
                                                                            <SVG_JENKINS width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '8px', paddingRight: '8px'}}>
                                                                            <SVG_TRAVIS width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                            <div style={{ textAlign: 'left', width: '100%' }}>
                                                                <p className="h6" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.info, textTransform: 'uppercase', fontWeight: 400}}>Monitoring</p>
                                                            </div>
                                                            <div style={{ textAlign: 'left', width: '100%'}}>
                                                                <Grid container spacing={0} sx={{ display: "flex" }}>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '16px', paddingRight: '16px'}}>
                                                                            <SVG_ELASTICSEARCH width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '16px', paddingRight: '16px'}}>
                                                                            <SVG_NAGIOS width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '4px', paddingRight: '4px'}}>
                                                                            <SVG_PROMETHEUS width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid sx={{ backgroundColor: theme.palette.background.paper, margin: '1px' }}>
                                                                        <div style={{ width: '100px', height: '64px', paddingLeft: '30px', paddingRight: '30px'}}>
                                                                            <SVG_GRAFANA width="100%" height="100%"/>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {articles.length>0&&
                        <>
                            <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.grey[100], padding: 0}}>
                                <div className="col col-12 col-sm-12 col-md-12 col-lg-11 col-xl-11">
                                    <div style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingLeft: '16px', paddingTop: '24px', paddingBottom: '24px', marginTop: '40px'}}>
                                        <p className="h1 font-weight-normal" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.common.black}}>Latest&nbsp;Insights</p>
                                        <Divider className="mb-0" sx={{ bgcolor: "secondary.light", width: '100px', borderWidth: '2px' }}/>
                                    </div>
                                    <div style={{textAlign: 'center', verticalAlign: 'middle', padding: '0px', paddingTop: '24px', paddingBottom: '24px'}}>
                                        <Grid container spacing={2}>
                                            {articles.map(item => (
                                                <Grid item key={item.id} xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <Button variant="text" fullWidth sx={{ textTransform: 'none', padding: '16px', display: 'block' }} onClick={(e) => {
                                                        e.preventDefault();
                                                        window.open(`${item.url}`, '_blank');
                                                    }}>
                                                        <div className='mt-0 mb-4 font-weight-light' style={{ textAlign: 'center'}}>
                                                            <img src={item.image.split('?')[0]} alt={item.title} width="100%" style={{ height: '340px' }}></img>
                                                        </div>
                                                        <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px' }}>
                                                            <p className="h6 mb-2 font-weight-normal" style={{fontFamily: 'inherit', color: 'red'}}>{item.authors[0].name}</p>
                                                            <p className="h4 mb-2 font-weight-normal" style={{fontFamily: 'inherit'}}>{item.title}</p>
                                                            <p className="h6 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>{item.content_text}</p>
                                                        </div>
                                                    </Button>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.grey[200], padding: 0, background: 'radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 200%)'}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className="row mt-4 mb-0 justify-content-md-center">
                                <div className="col col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-block d-lg-none">
                                    <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'center', verticalAlign: 'middle', padding: '10px', paddingTop: '40px'}}>
                                        <p className="h6 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>DG <strong>CAREERS</strong></p>
                                        <p className="h2 mb-2 font-weight-normal" style={{fontFamily: 'inherit'}}>Beyond is where we begin.</p>
                                        <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                            <div style={{ textAlign: 'center', padding: '0px', marginTop: '-24px'}}>
                                                <SVG_3 width={300} height={300}/>
                                            </div>
                                        </div>
                                        <p className="h5 mb-4 font-weight-light" style={{fontFamily: 'inherit', marginTop: '-24px'}}>Our culture of fostering employee growth and implementing effective management practices give our employees a continuous edge in their professional development.</p>
                                        <div style={{ textAlign: 'inherit', marginBottom: '40px' }}>
                                            <Button className="mb-4" variant="contained" style={{ padding: '10px', paddingLeft: '16px', paddingRight: '16px', fontSize: '14px', textTransform: 'none' }} onClick={(e) => { 
                                                e.preventDefault();
                                                history.push('/careers')
                                            }}>
                                                Visit&nbsp;our&nbsp;careers&nbsp;page&nbsp;<ArrowCircleRightOutlinedIcon fontSize="small"/>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 d-none d-lg-block">
                                    <div className='mb-0 font-weight-light' style={{textAlign: 'right'}}>
                                        <div style={{ textAlign: 'center', padding: '0px'}}>
                                            <SVG_3 width={350} height={350}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-none d-lg-block">
                                    <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '40px'}}>
                                        <p className="h6 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>OPS51 <strong>CAREERS</strong></p>
                                        <p className="h2 mb-2 font-weight-normal" style={{fontFamily: 'inherit'}}>Discover your path to success.</p>
                                        <p className="h5 mb-2 mt-3 font-weight-light" style={{fontFamily: 'inherit'}}>Our culture of fostering employee growth and implementing effective management practices give our employees a continuous edge in their professional development.</p>
                                        <div style={{ textAlign: 'inherit' }}>
                                            <Button className="mt-4 mb-4" variant="contained" style={{ padding: '10px', paddingLeft: '16px', paddingRight: '16px', fontSize: '14px', textTransform: 'none' }} onClick={(e) => { 
                                                e.preventDefault();
                                                history.push('/careers')
                                            }}>
                                                Visit&nbsp;our&nbsp;careers&nbsp;page&nbsp;<ArrowCircleRightOutlinedIcon fontSize="small"/>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-start-title"
                aria-describedby="modal-start-description"
            >
                <Box sx={style}>
                    <Typography id="modal-start-title" variant="h5" component="h2" sx={{ fontWeight: 500 }}>
                        Get More Value from IT with OPS51
                    </Typography>
                    <Typography id="modal-start-description" sx={{ mt: 2, mb: 3 }}>
                        Please fill out the form, and our rep will get back to you shortly to discuss your needs.
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <TextField id="inquiry-name" type="text" label="Name" variant="filled" placeholder="John Smith" fullWidth required/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <TextField id="inquiry-email" type="email" label="Email" variant="filled" placeholder="johnsmith@example.com" fullWidth required/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <TextField id="inquiry-organization" type="text" label="Organization" variant="filled" placeholder="Abc Company" fullWidth required/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <TextField id="inquiry-phone" type="tel" label="Contact Number" variant="filled" placeholder="1 800 123 4567" fullWidth required />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField id="inquiry-message" type="text" label="Message" variant="filled" placeholder="Your message..." fullWidth multiline rows={6}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Button variant="contained" style={{ padding: '10px', paddingLeft: '16px', paddingRight: '16px', fontSize: '14px'}} onClick={(e) => { 
                                e.preventDefault();
                            }}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}

export default Home;