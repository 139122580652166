import React, {useState} from "react";
import { Grid, Button, Card, CardContent, CardMedia } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// import { useHistory } from 'react-router-dom';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExploreIcon from '@mui/icons-material/Explore';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SpeedIcon from '@mui/icons-material/Speed';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';

import { ReactComponent as SVG_1 } from "../../../assets/career.svg";
import { ReactComponent as SVG_2 } from "../../../assets/student.svg";
import { ReactComponent as SVG_3 } from "../../../assets/professional.svg";
import { ReactComponent as SVG_4 } from "../../../assets/executive.svg";
import BG_1 from '../../../assets/img/knTKij60p3g-unsplash.jpg';
import BG_2 from '../../../assets/img/knTKij60p3g-unsplash.jpg';
import IMG_1 from '../../../assets/img/6CgugIFqpPw-unsplash.jpg';
import IMG_2 from '../../../assets/img/XRv8Mdod6ag-unsplash.jpg';
import IMG_3 from '../../../assets/img/dMzFhYwMHGE-unsplash.jpg';

const Career = () => {
    const theme = useTheme();
    const [openAlert, setOpenAlert] = useState(false);
    // const history = useHistory();

    const toggleAlert = () => {
        setOpenAlert(!openAlert);
    }

    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.common.black, padding: 0, minHeight: '500px', marginTop: '-10px', paddingTop: '24px', paddingBottom: '24px', background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 40%, rgba(0,212,255,1) 100%)'}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className="row mt-4 mb-4 justify-content-md-center">
                                <div className="col col-12 col-sm-12 col-md-10 col-lg-7 col-xl-7 d-block d-lg-none">
                                    <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '10px'}}>
                                        <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                            <div style={{ textAlign: 'left', padding: '0px', marginTop: '0px', marginBottom: '40px'}}>
                                                <SVG_1 width="300px" height="auto"/>
                                            </div>
                                        </div>
                                        <p className="h3 mb-4 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.grey[400]}}>OPS51 & You - A Remarkable Journey</p>
                                        <p className="h2 mb-4 font-weight-normal" style={{fontFamily: 'inherit', fontSize: '40px', color: theme.palette.grey[300]}}>Empower your career with cutting-edge software innovations</p>
                                        <p className="h5 mb-4 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.grey[300]}}>If you’re ready to do more than you think you can do, take a bold step toward OPS51 and we’ll walk that path with you.</p>
                                    </div>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-none d-lg-block">
                                    <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '60px'}}>
                                        <p className="h3 mb-4 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.grey[400]}}>OPS51 & You - A Remarkable Journey</p>
                                        <p className="h2 mb-4 font-weight-normal" style={{fontFamily: 'inherit', fontSize: '40px', color: theme.palette.grey[300]}}>Empower your career with cutting-edge software innovations</p>
                                        <p className="h5 mb-4 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.grey[300]}}>If you’re ready to do more than you think you can do, take a bold step toward OPS51 and we’ll walk that path with you.</p>
                                    </div>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 d-none d-lg-block">
                                    <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                        <div style={{ textAlign: 'center', padding: '0px', marginTop: '24px'}}>
                                            <SVG_1 width="400px" height="400px"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.grey[100], padding: 0}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className='font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '24px', paddingBottom: '24px'}}>
                                <p className="h3 mb-4 font-weight-normal" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.common.black}}>Ready to make a difference?</p>
                                <hr style={{backgroundColor: theme.palette.grey[100], margin: 0}}/>
                            </div>
                            <div className='font-weight-light' style={{textAlign: 'center', verticalAlign: 'middle', padding: '10px', paddingBottom: '80px'}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <Card variant="outlined" sx={{ textTransform: 'none', padding: '0px', display: 'block', borderRadius: '0px', backgroundColor: theme.palette.common.white, minHeight: '470px'}}>
                                            <SVG_2 width="auto" height="240px"/>
                                            <CardContent>
                                                <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px'}}>
                                                    <p className="h3 mb-4 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>Student / Early Career</p>
                                                    <p className="h5 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>Chances are you're not looking for business as usual. Find out more about our entry level opportunities.</p>
                                                </div>
                                                <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px' }}>
                                                    <p className="h6 mb-2 font-weight-normal" style={{fontFamily: 'inherit'}}>Learn more <ArrowCircleRightOutlinedIcon color="info"/></p>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <Card variant="outlined" sx={{ textTransform: 'none', padding: '0px', display: 'block', borderRadius: '0px', backgroundColor: theme.palette.common.white, minHeight: '470px'}}>
                                            <SVG_3 width="auto" height="240px"/>
                                            <CardContent>
                                                <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px'}}>
                                                    <p className="h3 mb-4 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>Professionals</p>
                                                    <p className="h5 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>A great workplace thrives on ideas and opportunity. Follow your ideas, and choose innovative career tracks.</p>
                                                </div>
                                                <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px' }}>
                                                    <p className="h6 mb-2 font-weight-normal" style={{fontFamily: 'inherit'}}>Learn more <ArrowCircleRightOutlinedIcon color="info"/></p>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <Card variant="outlined" sx={{ textTransform: 'none', padding: '0px', display: 'block', borderRadius: '0px', backgroundColor: theme.palette.common.white, minHeight: '470px'}}>
                                            <SVG_4 width="auto" height="240px"/>
                                            <CardContent>
                                                <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px'}}>
                                                    <p className="h3 mb-4 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>Executives</p>
                                                    <p className="h5 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>Are you ready to lead and influence change. Discover what we have to offer, and what we can do together.</p>
                                                </div>
                                                <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px' }}>
                                                    <p className="h6 mb-2 font-weight-normal" style={{fontFamily: 'inherit'}}>Learn more <ArrowCircleRightOutlinedIcon color="info"/></p>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                    <hr style={{backgroundColor: theme.palette.grey[100], margin: 0}}/>
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.grey[100], padding: 0}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10">
                            <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'center', verticalAlign: 'middle', padding: '10px', paddingTop: '60px', paddingBottom: '60px'}}>
                                <p className="h1 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.common.black, letterSpacing: 0.7}}>Our Benefits</p>
                                <p className="h4 mt-3 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.common.black, letterSpacing: 0.7}}>Our workforce has grown because we make taking care of our employees a priority.</p>
                            </div>
                            <div className='font-weight-light' style={{textAlign: 'center', verticalAlign: 'middle', padding: '10px', paddingBottom: '40px'}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <Card variant="outlined" sx={{ textTransform: 'none', padding: '10px', display: 'block', border: 'none', borderRadius: '0px', backgroundColor: theme.palette.grey[100], height: '200px'}}>
                                            <div className='mt-0 mb-3' style={{ textAlign: 'center'}}>
                                                <ExploreIcon color="info" style={{ fontSize: '60px' }}/>
                                            </div>
                                            <div className='mt-0 mb-0' style={{ textAlign: 'center'}}>
                                                <p className="h4 mb-2 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>Shape your career</p>
                                                <p className="h6 mb-4 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.grey[700] }}>Bring your ideas and pursue innovative career tracks, opportunities, and job rotations. </p>
                                            </div>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <Card variant="outlined" sx={{ textTransform: 'none', padding: '10px', display: 'block', border: 'none', borderRadius: '0px', backgroundColor: theme.palette.grey[100], height: '200px'}}>
                                            <div className='mt-0 mb-3' style={{ textAlign: 'center'}}>
                                                <RocketLaunchIcon color="info" style={{ fontSize: '60px' }}/>
                                            </div>
                                            <div className='mt-0 mb-0' style={{ textAlign: 'center'}}>
                                                <p className="h4 mb-2 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>Learn and grow</p>
                                                <p className="h6 mb-4 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.grey[700] }}>Enhance your professional development through education and training.</p>
                                            </div>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <Card variant="outlined" sx={{ textTransform: 'none', padding: '10px', display: 'block', border: 'none', borderRadius: '0px', backgroundColor: theme.palette.grey[100], height: '200px'}}>
                                            <div className='mt-0 mb-3' style={{ textAlign: 'center'}}>
                                                <SpeedIcon color="info" style={{ fontSize: '60px' }}/>
                                            </div>
                                            <div className='mt-0 mb-0' style={{ textAlign: 'center'}}>
                                                <p className="h4 mb-2 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>Keep current</p>
                                                <p className="h6 mb-4 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.grey[700] }}>Our skills training helps you keep pace with the changing workplace.</p>
                                            </div>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <Card variant="outlined" sx={{ textTransform: 'none', padding: '10px', display: 'block', border: 'none', borderRadius: '0px', backgroundColor: theme.palette.grey[100], height: '200px'}}>
                                            <div className='mt-0 mb-3' style={{ textAlign: 'center'}}>
                                                <VolunteerActivismIcon color="info" style={{ fontSize: '60px' }}/>
                                            </div>
                                            <div className='mt-0 mb-0' style={{ textAlign: 'center'}}>
                                                <p className="h4 mb-2 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>Give back</p>
                                                <p className="h6 mb-4 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.grey[700] }}>Help improve our communities and environment.</p>
                                            </div>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <Card variant="outlined" sx={{ textTransform: 'none', padding: '10px', display: 'block', border: 'none', borderRadius: '0px', backgroundColor: theme.palette.grey[100], height: '200px'}}>
                                            <div className='mt-0 mb-3' style={{ textAlign: 'center'}}>
                                                <HealthAndSafetyIcon color="info" style={{ fontSize: '60px' }}/>
                                            </div>
                                            <div className='mt-0 mb-0' style={{ textAlign: 'center'}}>
                                                <p className="h4 mb-2 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>Stay healthy</p>
                                                <p className="h6 mb-4 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.grey[700] }}>Be well with health plans that help you support your loved ones.</p>
                                            </div>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <Card variant="outlined" sx={{ textTransform: 'none', padding: '10px', display: 'block', border: 'none', borderRadius: '0px', backgroundColor: theme.palette.grey[100], height: '200px'}}>
                                            <div className='mt-0 mb-3' style={{ textAlign: 'center'}}>
                                                <SignalCellularAltIcon color="info" style={{ fontSize: '60px' }}/>
                                            </div>
                                            <div className='mt-0 mb-0' style={{ textAlign: 'center'}}>
                                                <p className="h4 mb-2 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>Invest in yourself</p>
                                                <p className="h6 mb-4 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.grey[700] }}>Plan for the future with our compensation and benefits packages, including holidays.</p>
                                            </div>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.info.dark, padding: 0, cursor: 'pointer'}} onClick={toggleAlert}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className="row justify-content-md-center">
                                <div className="col col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9">
                                    <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px'}}>
                                        <p className="h4 mt-2 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.common.white, letterSpacing: 0.7}}><strong>FRAUD ALERT:&nbsp;</strong>Stay safe as you search for jobs</p>
                                    </div>
                                </div>
                                <div className="col col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                    <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'right', verticalAlign: 'middle', padding: '10px', height: '100%'}}>
                                        <p className="h4 mt-1 mb-0 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.common.white, letterSpacing: 0.7}}>{openAlert?<ArrowDropDownIcon fontSize="large"/>:<ArrowRightIcon fontSize="large"/>}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {openAlert&&<>
                        <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.grey[300], padding: 0}}>
                            <div className="col col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8">
                                <div className='font-weight-light' style={{textAlign: 'center', verticalAlign: 'middle', padding: '10px', paddingTop: '24px', paddingBottom: '24px', marginTop: '40px'}}>
                                    <p className="h4 mb-4 font-weight-normal" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.error.dark}}>Beware of Fraudulent Offers</p>
                                    <p className="h6 mb-4 font-weight-light" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.common.black}}>Some job candidates have reported receiving fraudulent offers after posting their resumes on certain websites and portals from people posing as OPS51 employees. These notifications may involve promises regarding the selection process. In some cases, prospective candidates are asked for payment as a condition for consideration. The documents may appear legitimate, with our company logo or personal details taken from social networking pages. They may include a job description and salary details.</p>
                                    <p className="h6 mb-4 font-weight-light" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.common.black}}>OPS51 does not charge a fee at any stage of the recruitment process and has not authorized any agencies or partners to collect any fee for recruitment.</p>
                                    <p className="h6 mb-0 font-weight-light" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.common.black}}>If you receive any offers you suspect are fraudulent, please email us at</p>
                                    <p className="h6 mb-4 font-weight-light" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.common.black}}><u>tagcompliance@ops51.com</u></p>
                                    <p className="h6 mb-4 font-weight-light" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.common.black}}>By making you aware of this, we hope to avoid, and ultimately prevent, unsuspecting prospective candidates from falling victim to this scam.</p>
                                </div>
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </>
    )
}

export default Career;