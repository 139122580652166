import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import dataJSON from "../../../data/company.json";
import { useTheme } from '@mui/material/styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box key={index} sx={{ p: 3 }}>
            <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    'id': `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const ServiceTabs = () => {
    const theme = useTheme();
    const services = dataJSON.content[1].data;
    const [value, setValue] = React.useState(0);

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: "100%", width: '100%' }}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                aria-label="Services tabs"
                textColor="secondary"
                indicatorColor="secondary"
                sx={{ borderRight: 1, borderColor: 'divider', minWidth: '160px'}}
            >
                {services.map((item, i) =>
                    <Tab label={item.label} style={{ alignItems: 'end', textAlign: 'right' }} {...a11yProps(i)} key={item.label} onClick={() => handleChange(i)}/>
                )}
            </Tabs>
            {services.map((item) =>
                <TabPanel key={item.id} value={value} index={item.id}>
                    <Card variant="outlined" sx={{ textTransform: 'none', padding: '16px', borderRadius: '0px', border: 'none', width: "100%", display: 'inline-flex'}}>
                        <CardMedia
                            sx={{ height: 200, width: 240 }}
                            image={item.img}
                            title={item.label}
                            component='div'
                        />
                        <CardContent variant="outlined" sx={{ width: "100%", p: 0, px: 3 }}>
                            <Typography className='font-weight-normal' variant='h5' component='div' sx={{ width: '100%', textAlign: 'left' }}>{item.label}</Typography>
                            <Typography className='font-weight-light' variant='body1' component='div' sx={{width: '100%', textAlign: 'left'}}>{item.description}</Typography>
                            <div className="row mt-3 justify-content-md-center" style={{width: '100%', textAlign: 'left', display: 'flex'}}>
                                {item.list.map((obj) =>
                                    <div key={obj.label} className="col col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ marginTop: '0px'}}>
                                        <Typography className="h6 mb-2" style={{textAlign: 'left', color: theme.palette.grey[900], opacity: 0.7}}><ArrowRightIcon fontSize="small" style={{ marginTop: '-4px'}}/> {obj.label}</Typography>
                                    </div>
                                )}
                            </div>
                        </CardContent>
                    </Card>
                </TabPanel>
            )}
        </Box>
    );
}

export default ServiceTabs;
