import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as NotFoundIllustration } from "../../../assets/404.svg";

const PageNotFound = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    <div className="wrapper">
                        <div className="header container">
                            <div className="row justify-content-md-center" style={{minHeight: '480px'}}>
                                <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div className='mb-4 font-weight-light' style={{textAlign: 'center'}}>
                                        <div className="mb-0" style={{ textAlign: 'center', padding: '0px'}}>
                                            <NotFoundIllustration width="300px" height="300px" />
                                        </div>
                                        <div className="mb-0" style={{ textAlign: 'center', padding: '0px', marginTop: '-60px', paddingBottom: '60px'}}>
                                            <Link className="btn btn-secondary mt-0" to="/">Go Back</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageNotFound;