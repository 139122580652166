import React, {useState, useEffect} from "react";
import { Button, Divider, Grid, Modal, Box, Typography, TextField, Card, CardContent } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';

import ServiceTabs from '../../../components/tabs/ServiceTabs';
import ServiceStepper from '../../../components/stepper/ServiceStepper';
import dataJSON from "../../../data/company.json";
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

import { ReactComponent as SVG_1 } from "../../../assets/stack.svg";
import { ReactComponent as SVG_2 } from "../../../assets/csa.svg";
import { ReactComponent as SVG_3 } from "../../../assets/dw.svg";
import { ReactComponent as SVG_4 } from "../../../assets/dm.svg";
import { ReactComponent as SVG_5 } from "../../../assets/et.svg";
import { ReactComponent as SVG_6 } from "../../../assets/bm.svg";
import { ReactComponent as SVG_7 } from "../../../assets/dv.svg";

import { ReactComponent as SVG_JIRA } from "../../../assets/svg/platforms/jira.svg";
import { ReactComponent as SVG_POWERBI } from "../../../assets/svg/platforms/powerbi.svg";
import { ReactComponent as SVG_SALESFORCE } from "../../../assets/svg/platforms/salesforce.svg";
import { ReactComponent as SVG_LOOKER } from "../../../assets/svg/platforms/looker.svg";
import { ReactComponent as SVG_TABLEAU } from "../../../assets/svg/platforms/tableau.svg";
import { ReactComponent as SVG_SERVICENOW } from "../../../assets/svg/platforms/servicenow.svg";
import { ReactComponent as SVG_CONFLUENCE } from "../../../assets/svg/platforms/confluence.svg";
import { ReactComponent as SVG_BITBUCKET } from "../../../assets/svg/platforms/bitbucket.svg";
import { ReactComponent as SVG_GITHUB } from "../../../assets/svg/platforms/github.svg";
import { ReactComponent as SVG_GITLAB } from "../../../assets/svg/platforms/gitlab.svg";

import { ReactComponent as SVG_AWS } from "../../../assets/svg/clouds/aws.svg";
import { ReactComponent as SVG_GCP } from "../../../assets/svg/clouds/gcp.svg";
import { ReactComponent as SVG_AZURE } from "../../../assets/svg/clouds/azure.svg";

import { ReactComponent as SVG_DOCKER } from "../../../assets/svg/devops/docker.svg";
import { ReactComponent as SVG_KUBERNETES } from "../../../assets/svg/devops/kubernetes.svg";
import { ReactComponent as SVG_OPENSHIFT } from "../../../assets/svg/devops/openshift.svg";
import { ReactComponent as SVG_MESOS } from "../../../assets/svg/devops/mesos.svg";
import { ReactComponent as SVG_ANSIBLE } from "../../../assets/svg/devops/ansible.svg";
import { ReactComponent as SVG_CHEF } from "../../../assets/svg/devops/chef.svg";
import { ReactComponent as SVG_TERRAFORM } from "../../../assets/svg/devops/terraform.svg";
import { ReactComponent as SVG_JENKINS } from "../../../assets/svg/devops/jenkins.svg";
import { ReactComponent as SVG_TRAVIS } from "../../../assets/svg/devops/travis.svg";
import { ReactComponent as SVG_HELM } from "../../../assets/svg/devops/helm.svg";
import { ReactComponent as SVG_ELASTICSEARCH } from "../../../assets/svg/devops/elasticsearch.svg";
import { ReactComponent as SVG_NAGIOS } from "../../../assets/svg/devops/nagios.svg";
import { ReactComponent as SVG_PROMETHEUS } from "../../../assets/svg/devops/prometheus.svg";
import { ReactComponent as SVG_GRAFANA } from "../../../assets/svg/devops/grafana.svg";



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: "flex",
    flexDirection: "column",
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    maxWidth: '600px',
    maxHeight: '600px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow: "hidden",
    overflowY: "auto",
    p: 4,
};

const Services = () => {
    const theme = useTheme();
    const history = useHistory();
    const mission_statement = dataJSON.mission.statement;
    const mission_description = dataJSON.mission.description;
    const MAX_ARTICLES = 4;
    const [open, setOpen] = useState(false);
    const [articles, setArticles] = useState([]);

    const rssFeed = "https://rss.app/feeds/v1.1/trthaibc5F29lJFs.json";

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const loadArticles = async () => {
        fetch(rssFeed, { headers: { Accept: "application/json"} })
        .then((res) => res.json())
        .then((data) => data.items.filter((item) => item.title.length > 0))
        .then((newArticles) => newArticles.slice(0, MAX_ARTICLES))
        .then((articles) => setArticles(articles))
        .catch((error) => console.log(error));
    };

    useEffect(() => {
        loadArticles();
    }, [MAX_ARTICLES]);

    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.grey[100], padding: 0, minHeight: '500px', paddingTop: '24px', paddingBottom: '24px', marginTop: '-10px', background: 'radial-gradient(circle, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)'}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className="row mt-4 mb-4 justify-content-md-center">
                                <div className="col col-12 col-sm-12 col-md-10 col-lg-7 col-xl-7 d-block d-lg-none">
                                    <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '10px'}}>
                                        <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                            <div style={{ textAlign: 'left', padding: '0px', marginTop: '0px', marginBottom: '40px'}}>
                                                <SVG_1 width="300px" height="auto"/>
                                            </div>
                                        </div>
                                        <p className="h3 mb-4 font-weight-light" style={{fontFamily: 'inherit'}}>Building the stack.</p>
                                        <p className="h2 mb-4 font-weight-normal" style={{fontFamily: 'inherit', fontSize: '40px'}}>We implement modern data tools to build capabilities and create scalable solutions.</p>
                                    </div>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-none d-lg-block">
                                    <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '60px'}}>
                                        <p className="h3 mb-4 font-weight-light" style={{fontFamily: 'inherit'}}>Building the stack.</p>
                                        <p className="h2 mb-4 font-weight-normal" style={{fontFamily: 'inherit', fontSize: '48px'}}>We implement modern data tools to build capabilities and create scalable solutions.</p>
                                    </div>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 d-none d-lg-block">
                                    <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                        <div style={{ textAlign: 'center', padding: '0px', marginTop: '24px'}}>
                                            <SVG_1 width="400px" height="400px"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.grey[100], padding: 0}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '16px'}}>
                                <div className="row justify-content-md-left" style={{backgroundColor: theme.palette.grey[100], padding: 0, marginTop: '24px'}}>
                                    <div style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingLeft: '16px', paddingTop: '24px', paddingBottom: '24px', marginTop: '0px', marginBottom: '24px'}}>
                                        <p className="h1 font-weight-normal" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.common.black}}>Our Services</p>
                                        <Divider className="mb-0" sx={{ bgcolor: "info.light", width: '100px', borderWidth: '2px' }}/>
                                    </div>
                                    <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div className="row mt-0 mb-0 justify-content-md-left" style={{ display: 'flex', paddingBottom: '32px' }}>
                                            <Card variant="outlined" className="mb-0 mr-0 col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '40px'}}>
                                                    <p className="h6 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>ASSESSING YOUR NEEDS</p>
                                                    <p className="h2 mb-2 font-weight-normal" style={{fontFamily: 'inherit'}}>Current State Analysis</p>
                                                    <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                                        <div style={{ textAlign: 'left', padding: '0px'}}>
                                                            <SVG_2 width={300} height={300}/>
                                                        </div>
                                                    </div>
                                                    <p className="h5 mt-2 mb-4 font-weight-light" style={{fontFamily: 'inherit'}}>At OPS<sup>51</sup>, we believe that assessment and alignment set the roadmap for an engagement’s success. Through a series of discovery and immersion activities, we examine your existing systems and processes to gain a comprehensive understanding of your current data capabilities and business challenges.</p>
                                                </div>
                                            </Card>
                                            <Card variant="outlined" className="mb-0 mr-0 col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '40px'}}>
                                                    <p className="h6 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>BUILDING THE PIPELINE</p>
                                                    <p className="h2 mb-2 font-weight-normal" style={{fontFamily: 'inherit'}}>Data Warehousing & Ingestion</p>
                                                    <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                                        <div style={{ textAlign: 'left', padding: '0px'}}>
                                                            <SVG_3 width={300} height={300}/>
                                                        </div>
                                                    </div>
                                                    <p className="h5 mt-2 mb-4 font-weight-light" style={{fontFamily: 'inherit'}}>Centralized data is the foundation of an effective data maturity journey. As your trusted warehousing advisor and implementation partner, our seasoned team of analysts and engineers leverage their technical expertise to build quality data ingestion pipelines and optimize environment performance.</p>
                                                </div>
                                            </Card>
                                            <Card variant="outlined" className="mb-0 mr-0 col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '40px'}}>
                                                    <p className="h6 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>SETTING UP YOUR DATA</p>
                                                    <p className="h2 mb-2 font-weight-normal" style={{fontFamily: 'inherit'}}>Data Modeling</p>
                                                    <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                                        <div style={{ textAlign: 'left', padding: '0px'}}>
                                                            <SVG_4 width={300} height={300}/>
                                                        </div>
                                                    </div>
                                                    <p className="h5 mt-2 mb-4 font-weight-light" style={{fontFamily: 'inherit'}}>You deserve high quality, consistent data that is structured to align with your business needs. Our multi-disciplinary team of data enthusiasts work at the intersection of data engineering and business strategy, to effectively clean, normalize, aggregate and shape your data for improved business support.</p>
                                                </div>
                                            </Card>
                                            <Card variant="outlined" className="mb-0 mr-0 col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '40px'}}>
                                                    <p className="h6 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>UNDERSTANDING YOUR CUSTOMER INTERACTIONS</p>
                                                    <p className="h2 mb-2 font-weight-normal" style={{fontFamily: 'inherit'}}>Event Tracking</p>
                                                    <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                                        <div style={{ textAlign: 'left', padding: '0px'}}>
                                                            <SVG_5 width={300} height={300}/>
                                                        </div>
                                                    </div>
                                                    <p className="h5 mt-2 mb-4 font-weight-light" style={{fontFamily: 'inherit'}}>Whether its establishing event tracking infrastructure or distilling your existing event data, we translate customer behavior into actionable insights.</p>
                                                </div>
                                            </Card>
                                            <Card variant="outlined" className="mb-0 mr-0 col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '40px'}}>
                                                    <p className="h6 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>MEASURING YOUR KPIS</p>
                                                    <p className="h2 mb-2 font-weight-normal" style={{fontFamily: 'inherit'}}>Business Metrics & Definitions</p>
                                                    <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                                        <div style={{ textAlign: 'left', padding: '0px'}}>
                                                            <SVG_6 width={300} height={300}/>
                                                        </div>
                                                    </div>
                                                    <p className="h5 mt-2 mb-4 font-weight-light" style={{fontFamily: 'inherit'}}>At its core, measurement and reporting are about establishing interpretable definitions for key business questions. We work closely with your stakeholders to bridge the gap between business strategy and data; implementing metrics that provide valuable insights for strategic decision making.</p>
                                                </div>
                                            </Card>
                                            <Card variant="outlined" className="mb-0 mr-0 col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '40px'}}>
                                                    <p className="h6 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>VISUALIZING YOUR DATA</p>
                                                    <p className="h2 mb-2 font-weight-normal" style={{fontFamily: 'inherit'}}>BI & Reporting</p>
                                                    <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                                        <div style={{ textAlign: 'left', padding: '0px' }}>
                                                            <SVG_7 width={300} height={300}/>
                                                        </div>
                                                    </div>
                                                    <p className="h5 mt-2 mb-4 font-weight-light" style={{fontFamily: 'inherit'}}>Have confidence in your decisions and get the most out of your data with the development of a seamless BI interface. From tooling selections through design and build, OPS<sup>51</sup> ensures that your BI tools are working to the fullest, for your business and users.</p>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.common.white, padding: 0, paddingTop: '24px', paddingBottom: '32px'}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingLeft: '16px', paddingTop: '24px', paddingBottom: '24px'}}>
                                <p className="h1 font-weight-normal" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.common.black}}>Connect with us</p>
                                <Divider className="mb-0" sx={{ bgcolor: "secondary.light", width: '100px', borderWidth: '2px' }}/>
                            </div>
                            <div className='mt-0 mb-0' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '0px'}}>
                                <p className="h5 mb-4 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.grey[900], letterSpacing: 0.7}}>Serving our clients, solving problems and enhancing human experiences motivate everything we do. If you're as passionate about the possibilities as we are, discover the best digital opportunities for your business.</p>
                                <p className="h5 mb-1 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.grey[900], letterSpacing: 0.7}}>- Follow us on Facebook, LinkedIn or Twitter.</p>
                                <p className="h5 mb-1 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.grey[900], letterSpacing: 0.7}}>- Explore the Latest News and learn how we elevate experiences.</p>
                                <p className="h5 mb-1 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.grey[900], letterSpacing: 0.7}}>- Search our locations below to find an office.</p>
                            </div>
                            <div className='mt-4 mb-0' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px'}}>
                                <Button variant="outlined" style={{ padding: '10px', paddingLeft: '16px', paddingRight: '16px', fontSize: '14px', marginTop: '-16px', marginBottom: '40px', textTransform: 'none'}} onClick={(e) => { 
                                    e.preventDefault();
                                }}>
                                    Contact our team&nbsp;<ArrowCircleRightOutlinedIcon fontSize="small"/>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-start-title"
                aria-describedby="modal-start-description"
            >
                <Box sx={style}>
                    <Typography id="modal-start-title" variant="h5" component="h2" sx={{ fontWeight: 500 }}>
                        Get More Value from IT with OPS51
                    </Typography>
                    <Typography id="modal-start-description" sx={{ mt: 2, mb: 3 }}>
                        Please fill out the form, and our rep will get back to you shortly to discuss your needs.
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <TextField id="inquiry-name" type="text" label="Name" variant="filled" placeholder="John Smith" fullWidth required/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <TextField id="inquiry-email" type="email" label="Email" variant="filled" placeholder="johnsmith@example.com" fullWidth required/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <TextField id="inquiry-organization" type="text" label="Organization" variant="filled" placeholder="Abc Company" fullWidth required/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <TextField id="inquiry-phone" type="tel" label="Contact Number" variant="filled" placeholder="1 800 123 4567" fullWidth required />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField id="inquiry-message" type="text" label="Message" variant="filled" placeholder="Your message..." fullWidth multiline rows={6}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Button variant="contained" style={{ padding: '10px', paddingLeft: '16px', paddingRight: '16px', fontSize: '14px'}} onClick={(e) => { 
                                e.preventDefault();
                            }}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}

export default Services;