import React, {useState, useEffect} from "react";
import { Grid, Card, CardContent, CardMedia } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// import { useHistory } from 'react-router-dom';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

import BG_1 from '../../../../assets/img/mslrSX69KvQ-unsplash.jpg';

const News = () => {
    const theme = useTheme();
    const MAX_ARTICLES = 3;
    const [articles, setArticles] = useState([]);
    // const history = useHistory();
    const loadArticles = async () => {
        setArticles([]);
    };

    useEffect(() => {
        loadArticles();
    }, [MAX_ARTICLES]);

    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.common.white, padding: 0, backgroundImage: `url(${BG_1})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'revert', minHeight: '400px', marginTop: '-10px'}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '150px', maxWidth: '600px'}}>
                                <p className="h1 mb-4 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.common.white, letterSpacing: 0.7}}>Newsroom</p>
                                <p className="h5 mb-4 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.common.white, letterSpacing: 0.7}}>Explore our latest press releases, and trending topics.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.common.white, padding: 0}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '60px'}}>
                                <p className="h1 mb-2 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.common.black, letterSpacing: 0.7}}>Press Releases</p>
                                <hr/>
                            </div>
                        </div>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className='mb-4 font-weight-light' style={{textAlign: 'center', verticalAlign: 'middle', padding: '10px', paddingTop: '24px', paddingBottom: '24px'}}>
                                <Grid container spacing={3}>
                                    {articles.length>0?
                                    <>
                                        {articles.map(item => (
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Card variant="outlined" sx={{ textTransform: 'none', padding: '16px', display: 'flex', borderRadius: '0px', cursor: 'pointer'}} onClick={(e) => {
                                                        e.preventDefault();
                                                        window.open(`${item.url}`, '_blank');
                                                    }}>
                                                    <CardMedia
                                                        sx={{ height: '200px', width: '200px' }}
                                                        image={item.image.split('?')[0]}
                                                        title={item.title}
                                                    />
                                                    <CardContent sx={{ display: 'block' }}>
                                                        <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px'}}>
                                                            <p className="h3 mb-2 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>{item.title}</p>
                                                            <p className="h5 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>{item.subject}</p>
                                                        </div>
                                                        <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px' }}>
                                                            <p className="h6 mb-2 font-weight-normal" style={{fontFamily: 'inherit'}}>Learn more <ArrowCircleRightOutlinedIcon color="info"/></p>
                                                        </div>  
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </>:<>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Card variant="outlined" sx={{ textTransform: 'none', padding: '16px', display: 'flex', borderRadius: '0px'}}>
                                                <CardContent sx={{ display: 'block' }}>
                                                    <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px'}}>
                                                        <p className="h3 mb-2 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>Not found</p>
                                                        <p className="h5 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>Please try again later.</p>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </>
                                    }
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default News;