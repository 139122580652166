import React from "react";
import { Grid, Card, CardContent, CardMedia, Button, TextField, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// import { useHistory } from 'react-router-dom';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import dataJSON from "../../../../data/company.json";
import BG_1 from '../../../../assets/img/bKESVqfxass-unsplash.jpg';

import { ReactComponent as SVG_1 } from "../../../../assets/target.svg";
import { ReactComponent as SVG_2 } from "../../../../assets/success.svg";
import { ReactComponent as SVG_3 } from "../../../../assets/together.svg";
import IMG_1 from '../../../../assets/img/DbLlKd8u2Rw-unsplash.jpg';
import IMG_2 from '../../../../assets/img/05gacQn0k4-unsplash.jpg';
import IMG_3 from '../../../../assets/img/376KNISplE-unsplash.jpg';

const Mission = () => {
    const theme = useTheme();
    const mission_statement = dataJSON.mission.statement;
    const mission_description = dataJSON.mission.description;
    // const history = useHistory();
    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.common.white, padding: 0, backgroundImage: `url(${BG_1})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'revert', minHeight: '500px', marginTop: '-10px'}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className='mb-0 font-weight-light' style={{textAlign: 'left', marginTop: '100px'}}>
                                <div style={{ textAlign: 'center', padding: '0px'}}>
                                    <SVG_1 width={120} height={120}/>
                                    <p className="h6 mb-0 font-weight-normal" style={{fontFamily: 'inherit', fontSize: '16px', marginTop: '-20px', color: theme.palette.grey[100]}}>Our Mission</p>
                                </div>
                            </div>
                            <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'center', verticalAlign: 'middle', padding: '10px', paddingTop: '24px', paddingBottom: '48px', color: theme.palette.common.white}}>
                                <p className="h1 mb-4 font-weight-normal" style={{fontFamily: 'inherit', fontSize: '42px'}}>{mission_statement}</p>
                                <p className="h3 mb-4 font-weight-light" style={{fontFamily: 'inherit', display: 'inline-block', maxWidth: '700px'}}>{mission_description}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.grey[100], padding: 0}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingLeft: '10px', paddingTop: '48px', paddingBottom: '24px'}}>
                                <p className="h1 font-weight-normal" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.common.black}}>Partnering to focus on you</p>
                                <Divider className="mb-0" sx={{ bgcolor: "info.light", width: '100px', borderWidth: '2px' }}/>
                            </div>
                            <div className='mt-0 mb-0' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '0px', paddingBottom: '24px'}}>
                                <p className="h4 mb-4 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.grey[900], letterSpacing: 0.7}}>Understand. Collaborate. Empower.</p>
                                <p className="h4 mb-4 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.grey[900], letterSpacing: 0.7}}>Client Briefings offer transformative experiences that lead to engaging conversations and actionable insights. Join us to explore business strategies that will help you grow your business. Our program offers innovative solutions, stimulating workshops and access to OPS51 thought leaders. Working with our experts, you can investigate new points of view based on proven methodologies and discover tangible ways to create new opportunities.</p>
                            </div>
                            <hr/>
                        </div>
                    </div>
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.grey[100], padding: 0}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '16px', paddingBottom: '24px'}}>
                                <div className="row justify-content-md-left" style={{backgroundColor: theme.palette.grey[100], padding: 0}}>
                                    <div style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingLeft: '10px', paddingTop: '24px', paddingBottom: '24px'}}>
                                        <p className="h1 font-weight-normal" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.common.black}}>Our Approach</p>
                                        <Divider className="mb-0" sx={{ bgcolor: "secondary.light", width: '100px', borderWidth: '2px' }}/>
                                    </div>
                                    <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div className="row mt-0 mb-0 justify-content-md-left" style={{ display: 'flex', paddingBottom: '32px' }}>
                                            <Card variant="outlined" className="mb-0 mr-0 col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '40px'}}>
                                                    <p className="h6 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>ASSESSING YOUR NEEDS</p>
                                                    <p className="h2 mb-2 font-weight-normal" style={{fontFamily: 'inherit'}}>A Blueprint for Success</p>
                                                    <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                                        <div style={{ textAlign: 'left', padding: '0px'}}>
                                                            <SVG_2 width={300} height={300}/>
                                                        </div>
                                                    </div>
                                                    <p className="h5 mt-2 mb-4 font-weight-light" style={{fontFamily: 'inherit'}}>At the start of every engagement, we partner with clients to define their data maturity and business goals. Then we build a customized project plan that translates this vision into action.</p>
                                                </div>
                                            </Card>
                                            <Card variant="outlined" className="mb-0 mr-0 col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '40px'}}>
                                                    <p className="h6 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>BUILDING THE PIPELINE</p>
                                                    <p className="h2 mb-2 font-weight-normal" style={{fontFamily: 'inherit'}}>Beyond the Build</p>
                                                    <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                                        <div style={{ textAlign: 'left', padding: '0px'}}>
                                                            <SVG_3 width={300} height={300}/>
                                                        </div>
                                                    </div>
                                                    <p className="h5 mt-2 mb-4 font-weight-light" style={{fontFamily: 'inherit'}}>We recognize tooling is only one piece of the data maturity journey. We empower your team with the right mix of best practices, processes, and coaching to foster the adoption and longevity of your investment.</p>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.grey[600], padding: 0, background: 'radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 200%)'}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '24px', paddingBottom: '24px', marginTop: '40px'}}>
                                <p className="h1 mb-3 font-weight-light" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.common.white}}>Accelerate your digital transformation</p>
                                <p className="h5 mb-3 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.common.white, letterSpacing: 0.7}}>Elevate your business by transforming it into one that anticipates the optimal course of action and instinctively takes action in critical moments.</p>
                            </div>
                            <div style={{textAlign: 'center', verticalAlign: 'middle', padding: '10px', paddingTop: '24px', paddingBottom: '48px', marginTop: '-36px'}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <Card variant="outlined" fullWidth sx={{ textTransform: 'none', padding: '0px', display: 'block', borderRadius: '0px', borderTop: 'none', borderLeft: 'none', borderRight: 'none', backgroundColor: theme.palette.grey[100], minHeight: '470px'}}>
                                            <CardMedia
                                                sx={{ height: 240 }}
                                                image={IMG_1}
                                                title="Collaborative solutions"
                                            />
                                            <CardContent>
                                                <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px'}}>
                                                    <p className="h3 mb-4 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>Collaborative solutions</p>
                                                    <p className="h5 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>Collaborate with senior executives and subject matter experts to help you identify solutions catered to your business needs.</p>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <Card variant="outlined" fullWidth sx={{ textTransform: 'none', padding: '0px', display: 'block', borderRadius: '0px', borderTop: 'none', borderLeft: 'none', borderRight: 'none', backgroundColor: theme.palette.grey[100], minHeight: '470px'}}>
                                            <CardMedia
                                                sx={{ height: 240 }}
                                                image={IMG_2}
                                                title="Customized outcomes"
                                            />
                                            <CardContent>
                                                <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px'}}>
                                                    <p className="h3 mb-4 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>Customized outcomes</p>
                                                    <p className="h5 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>Customized and targeted experiences, providing live demos to deep dive into service offerings tailored to your business objectives and outcomes.</p>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <Card variant="outlined" fullWidth sx={{ textTransform: 'none', padding: '0px', display: 'block', borderRadius: '0px', borderTop: 'none', borderLeft: 'none', borderRight: 'none', backgroundColor: theme.palette.grey[100], minHeight: '470px'}}>
                                            <CardMedia
                                                sx={{ height: 240 }}
                                                image={IMG_3}
                                                title="Targeted growth"
                                            />
                                            <CardContent>
                                                <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px'}}>
                                                    <p className="h3 mb-4 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>Targeted growth</p>
                                                    <p className="h5 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>Build a strong partnership that will result in a clear path to a future where your business growth and expansion is enabled by digital technology.</p>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.common.white, padding: 0}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className='font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '24px', paddingBottom: '24px', marginTop: '40px'}}>
                                <p className="h1 mb-2 font-weight-normal" style={{fontFamily: 'inherit', letterSpacing: 0.7}}>Get answers to your questions</p>
                                <p className="h5 mb-2 font-weight-light" style={{fontFamily: 'inherit', letterSpacing: 0.7}}>Our clients turn to us to help them reimagine ways of working with technology.</p>
                            </div>
                            <div className='font-weight-light' style={{textAlign: 'center', verticalAlign: 'middle', padding: '10px', paddingTop: '24px', paddingBottom: '40px', marginTop: '-24px'}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <TextField id="inquiry-name" type="text" label="Name" variant="filled" placeholder="John Smith" fullWidth required/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <TextField id="inquiry-email" type="email" label="Email" variant="filled" placeholder="johnsmith@example.com" fullWidth required/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <TextField id="inquiry-organization" type="text" label="Organization" variant="filled" placeholder="Abc Company" fullWidth required/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <TextField id="inquiry-phone" type="tel" label="Contact Number" variant="filled" placeholder="1 800 123 4567" fullWidth required />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField id="inquiry-message" type="text" label="Message" variant="filled" placeholder="Your message..." fullWidth multiline rows={5}/>
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{ textAlign: 'left', padding: '10px' }}>
                                <Button className="mb-4" variant="contained" style={{ padding: '10px', paddingLeft: '16px', paddingRight: '16px', fontSize: '14px', marginTop: '-24px'}} onClick={(e) => { 
                                    e.preventDefault();
                                }}>
                                    Submit&nbsp;<ArrowCircleRightOutlinedIcon fontSize="small"/>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Mission;