import React from "react";
import ScrollToTop from "./components/scroll/ScrollToTop";
import PublicRouter from "./components/routers/PublicRouter";
import PublicTemplate from "./components/templates/PublicTemplate";

import { BrowserRouter as Router, Switch } from "react-router-dom";

// APP CSS
import "./App.css";

// PUBLIC
import Home from './pages/public/Home';
import Career from './pages/public/Career';
import Services from './pages/public/Services';
import Contact from './pages/public/About/Contact';
import News from './pages/public/About/News';
import Mission from './pages/public/About/Mission';
import Company from './pages/public/About/Company';
import PageNotFound from './pages/public/PageNotFound';

function App() {
	return (
		<Router>
			<ScrollToTop>
				<Switch>
					<PublicRouter exact path="/" component={Home} template={PublicTemplate} title="OPS51 Technologies" />
					<PublicRouter exact path="/services" component={Services} template={PublicTemplate} title="OPS51 Technologies" />
					<PublicRouter exact path="/careers" component={Career} template={PublicTemplate} title="OPS51 Technologies" />
					<PublicRouter exact path="/about/company" component={Company} template={PublicTemplate} title="OPS51 Technologies" />
					<PublicRouter exact path="/about/newsroom" component={News} template={PublicTemplate} title="OPS51 Technologies" />
					<PublicRouter exact path="/about/mission" component={Mission} template={PublicTemplate} title="OPS51 Technologies" />
					<PublicRouter exact path="/about/contact-us" component={Contact} template={PublicTemplate} title="OPS51 Technologies" />
					<PublicRouter component={PageNotFound} template={PublicTemplate} title="OPS51 Technologies" />
				</Switch>
			</ScrollToTop>
        </Router>
  	)
}

export default App;
