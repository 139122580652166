import React, {Fragment} from 'react';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  usePopupState,
  bindHover,
  bindMenu,
} from 'material-ui-popup-state/hooks';

const AppTabs = ({ content }) => {
    const theme = useTheme();
    const history = useHistory();
    const popupState = usePopupState({
        variant: 'popover',
        popupId: content.label
    })
    return (
        <Fragment>
            <Button variant="outlined" {...bindHover(popupState)} sx={{ textTransform: 'none', p: 2, fontSize: '12px', borderRadius: 0,height: '100%' ,width: '90px', border: 'none', color: theme.palette.grey[300], "&:hover,&:focus": {backgroundColor: theme.palette.grey[900],color: theme.palette.grey[300],borderWidth:0}}} onClick={() => {
                history.push(content.path);
            }}>
                {content.label} {content.data.length>0&&<KeyboardArrowDownIcon fontSize='small'/>}
            </Button>
            {content.data.length>0&&
                <HoverMenu
                    {...bindMenu(popupState)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    {content.data.map((item) =>
                        <MenuItem key={item.label} onClick={() => {popupState.close(); history.push(item.path)}} sx={{ minWidth: '166px', fontSize: '12px' }}>{item.label}</MenuItem>
                    )}
                </HoverMenu>
            }
        </Fragment>
    )
}

export default AppTabs;