import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
// import { Link } from "react-router-dom";
import { AppBar as MuiAppBar, Toolbar, CssBaseline, Box, Divider, Drawer, IconButton, List, ListItem, ListItemText, ListItemIcon, Typography, Card, CardMedia, CardContent, Button } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ReactComponent as LogoIllustration } from "../../../assets/logo_dark.svg";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AppTabs from '../../tabs/AppTabs';
import dataJSON from "../../../data/company.json";

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  minHeight: '56px',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: '0px',
    width: '100%',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const PublicTemplate = ({children}) => {
  const history = useHistory();
  const mode = 'light';
  const appbar = dataJSON.content;
  const abouts = dataJSON.content[0].data;
  const services = dataJSON.content[1].data;
  
  const [tabData, setTabData] = useState({"id": 0, "label": "", "img": "", "description": "", "data": []});
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(false);
  
  const handleDrawerOpen = () => {
    setOpen(true);
    setTab(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setTab(false);
  };

  const handleOpen = (data) => {
    setTab(false);
    setTabData(data);
  }

  const handleClose = () => {
    setTab(true);
    setTabData({"id": 0, "label": "", "img": "", "description": "", "data": []});
  }

  const handleCloseAll = () => {
    setOpen(false);
    setTab(false);
  }

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          background: {
            default: '#f9f9f9'
          },
          primary: {
            main: '#212121',
          },
          mode,
        },
      }),
    [mode],
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex'}}>
        <CssBaseline />
        <AppBar className="d-none d-lg-block" position="fixed" enableColorOnDark={false} sx={{ bgcolor: theme.palette.common.black}}>
          <Toolbar disableGutters style={{paddingLeft: '16px', paddingRight: '16px', minHeight: '56px'}}>
              <div style={{paddingLeft: '0px', paddingRight: '0px', display: "inline-flex", color: theme.palette.grey[300], cursor: 'pointer'}} onClick={() => {
                handleCloseAll();
                history.push('/');
              }}><LogoIllustration height="28px"/></div>
              <div style={{marginLeft: "0px", marginRight: "auto"}}>
                {appbar.map((item, i) =>
                  <AppTabs key={item.label} content={item}/>
                )}
              </div>
              <div style={{marginLeft: "auto", marginRight: "0px"}}>
                <Button variant='outlined' color='info' style={{ fontSize: '12px' }} onClick={(e)=> {
                  e.preventDefault();
                  history.push('/about/contact-us')
                }}>Contact us</Button>
              </div>
          </Toolbar>
        </AppBar>
        <AppBar className="d-block d-lg-none" position="fixed" enableColorOnDark={false} sx={{ bgcolor: theme.palette.common.black}}>
            <Toolbar disableGutters style={{paddingLeft: '16px', paddingRight: '16px', minHeight: '56px'}}>
                <div style={{paddingLeft: '0px', paddingRight: '0px', display: "inline-flex", color: theme.palette.primary.main, cursor: 'pointer'}} onClick={() => history.push('/')}><LogoIllustration height="28px" /></div>
                <div style={{marginLeft: "auto", marginRight: "0px"}}>
                  <IconButton
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="end"
                    sx={{
                      marginLeft: '5px',
                      color: theme.palette.grey[300],
                      ...(open && { display: 'none' }),
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    aria-label="close drawer"
                    onClick={handleDrawerClose}
                    edge="end"
                    sx={{
                      marginLeft: '5px',
                      color: theme.palette.grey[300],
                      ...(!open && { display: 'none' }),
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
            </Toolbar>
        </AppBar>
        <Box component="main" sx={{ flexGrow: 1, overflowY: 'hidden', bgcolor:'white'}}>
          <DrawerHeader/>
          <div style={{position: 'relative'}}>
            <Box mt={0} ml={0} mr={0} mb={0} style={{ textAlign: 'center'}}>
              {children}
            </Box>
          </div>
          <Drawer
            anchor="right"
            open={open}
            hideBackdrop={false}
            onClose={handleDrawerClose}
            className="d-block d-lg-none"
          >
            <Box style={{ maxWidth: '100%', width: '320px'}}>
              <Divider sx={{ mt: '56px' }}/>
              <List sx={{ p: 0 }}>
                {tab?(
                  <div>
                    {appbar.map((item) => 
                      <ListItem button key={item.label} onClick={() => {
                        if(item.data.length>0){
                          handleOpen(item);
                        } else {
                          handleCloseAll();
                          history.push(item.path);
                        }
                      }}>
                        <ListItemText primary={<Typography color="textPrimary">{item.label}</Typography>} />
                        <ListItemIcon sx={{minWidth: '32px', display: item.data.length>0?'block':'none'}}><KeyboardArrowRightIcon fontSize='small'/></ListItemIcon>
                      </ListItem>
                    )}
                    <Divider />
                    <ListItem button key="Contact">
                        <ListItemText primary={<Typography color="textPrimary">Contact Us</Typography>} />
                    </ListItem>
                  </div>
                ):(
                  <div>
                    <ListItem button key="Back" onClick={handleClose}>
                        <ListItemIcon sx={{minWidth: '32px'}}><KeyboardArrowLeftIcon /></ListItemIcon>
                        <ListItemText primary={<Typography color="textPrimary">Back</Typography>} />
                    </ListItem>
                    <Divider />
                    <Card variant="outlined" sx={{ textTransform: 'none', padding: '16px', paddingBottom: '0px', borderRadius: '0px', border: 'none', width: "100%"}}>
                        <CardMedia
                            sx={{ height: 100, m: -2, mb:0 }}
                            image={tabData.img}
                            title="Services"
                            component='div'
                        />
                        <CardContent sx={{ p: 0 , mt: 2}}>
                            <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left'}}>
                                <p className="h4 mb-3 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>{tabData.label}</p>
                                <p className="h6 mb-0 font-weight-light" style={{fontFamily: 'inherit', opacity: 0.9 }}>{tabData.description}</p>
                            </div>
                        </CardContent>
                    </Card>
                    <Divider />
                    {tabData.data.map((item) => 
                      <ListItem button key={item.label} onClick={() => {
                        handleCloseAll();
                        history.push(item.path);
                        }}>
                          <ListItemText primary={<Typography color="textPrimary">{item.label}</Typography>} />
                      </ListItem>
                    )}
                  </div>
                )}
              </List>
            </Box>
          </Drawer>
          <footer style={{ width: '100%', position: 'relative', bottom: 0 }}>
            <div className="c-footer c-footer-light" style={{height: 'fit-content', backgroundColor: theme.palette.grey[900], textAlign: 'center'}}>
              <div className="row justify-content-md-center" style={{width: '100%', display: 'contents', textAlign: 'center'}}>
                <div className="col col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3" style={{textAlign: 'justify', paddingTop: '16px', paddingBottom: '0px', paddingLeft: '16px', paddingRight: '16px'}}>
                  <div style={{paddingLeft: '0px', paddingRight: '0px', paddingBottom: '0px', paddingTop: '16px', display: "inline-flex", color: theme.palette.primary.main, cursor: 'pointer', marginLeft: '-8px'}} onClick={() => history.push('/')}><LogoIllustration height="32px" /></div>
                </div>
                <div className="col col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9" style={{textAlign: 'justify', paddingTop: '32px', paddingBottom: '16px', paddingLeft: '16px', paddingRight: '16px'}}>
                  <div className="row justify-content-md-left" style={{width: '100%', textAlign: 'center', display: 'flex'}}>
                    <div className="col col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style={{textAlign: 'left', paddingTop: '0px', paddingLeft: '15px', paddingRight: '15px'}}>
                      <div className="row justify-content-md-center" style={{width: '100%', textAlign: 'center', display: 'flex'}}>
                        <div className="col col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{textAlign: 'left', marginTop: '0px'}}>
                          <p className="h5 mb-3" style={{textAlign: 'left', color: theme.palette.grey[300], opacity: 0.9}}>Services</p>
                        </div>
                        <div className="col col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{textAlign: 'left', marginTop: '0px'}}>
                          <p className="h5 mb-3" style={{textAlign: 'left', color: theme.palette.grey[300], opacity: 0.9, cursor: 'pointer', width: 'fit-content'}} onClick={(e) => {
                            e.preventDefault();
                            history.push('/technologies');
                          }}>Technologies</p>
                        </div>
                        <div className="col col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{textAlign: 'left', marginTop: '0px'}}>
                          <p className="h5 mb-3" style={{textAlign: 'left', color: theme.palette.grey[300], opacity: 0.9, cursor: 'pointer', width: 'fit-content'}} onClick={(e) => {
                            e.preventDefault();
                            history.push('/careers');
                          }}>Careers</p>
                        </div>
                      </div>
                    </div>
                    <div className="col col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4" style={{textAlign: 'left', paddingTop: '0px', paddingLeft: '15px', paddingRight: '15px'}}>
                      <div className="row justify-content-md-center" style={{width: '100%', textAlign: 'center', display: 'flex'}}>
                        <div className="col col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{textAlign: 'left', marginTop: '0px'}}>
                          <p className="h5 mb-2" style={{textAlign: 'left', color: theme.palette.grey[300], opacity: 0.9, cursor: 'pointer'}}>Company</p>
                          {abouts.length > 0 && (
                            <>
                              {abouts.map((item, i) =>
                                <p className="h6 mb-1" key={item.id} style={{textAlign: 'left', color: theme.palette.grey[300], opacity: 0.7, cursor: 'pointer'}} onClick={() => {history.push(item.path)}}><small>{item.label}</small></p>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{textAlign: 'justify', paddingTop: '0px', paddingBottom: '16px', paddingLeft: '16px', paddingRight: '16px'}}>
                  <p className="h6 mb-3" style={{textAlign: 'justify', color: theme.palette.grey[300], opacity: 0.7}}><small><a target="_blank" rel="noreferrer" href="https://ops51.com/legal/disclaimer" style={{ color: theme.palette.grey[300] }}><u>Legal Disclaimer</u></a>&nbsp;&nbsp;|&nbsp;&nbsp;<a target="_blank" rel="noreferrer" href="https://ops51.com/legal/privacy" style={{ color: theme.palette.grey[300] }}><u>Privacy Policy</u></a></small></p>
                  <p className="h6 mb-3" style={{textAlign: 'justify', color: theme.palette.grey[300], opacity: 0.7}}><small>OPS51 Technologies ("OPS51"), is an Information Technology company, providing technology consulting and staffing services to a diverse range of clients. Our services are designed to support clients in achieving their business goals through the implementation of best practices and tailored solutions. While we make every effort to ensure the accuracy and reliability of the information and recommendations provided, the results may vary based on a number of factors specific to each client. Our statements and opinions are not intended to be a guarantee of results or investment return and should not be relied upon as such. OPS51 does not accept any liability for any loss or damage suffered as a result of reliance upon information and opinions provided.</small></p>
                  <p className="h6 mb-3" style={{textAlign: 'left', color: theme.palette.grey[300], opacity: 0.7}}><small>Copyright&nbsp;©&nbsp;2020-2024 OPS51&nbsp;Technologies</small></p>
                </div>
              </div>
            </div>
          </footer>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default PublicTemplate;