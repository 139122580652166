import React from "react";
import { Grid, Card, CardContent, CardMedia, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// import { useHistory } from 'react-router-dom';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

import BG_1 from '../../../../assets/img/knTKij60p3g-unsplash.jpg';
import BG_2 from '../../../../assets/img/6CgugIFqpPw-unsplash.jpg';
import IMG_1 from '../../../../assets/img/A1AVH8ZBaM-unsplash.jpg';
import IMG_2 from '../../../../assets/img/dMzFhYwMHGE-unsplash.jpg';
import IMG_3 from '../../../../assets/img/XRv8Mdod6ag-unsplash.jpg';
import IMG_4 from '../../../../assets/people/1609786655564.jpg'

const Company = () => {
    const theme = useTheme();
    // const history = useHistory();
    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.common.white, padding: 0, backgroundImage: `url(${BG_1})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'revert', minHeight: '500px', marginTop: '-10px'}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '150px', maxWidth: '600px'}}>
                                <p className="h5 mb-4 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.common.white, letterSpacing: 0.7}}>About OPS51</p>
                                <p className="h1 mb-4 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.common.white, letterSpacing: 0.7}}>Determined to make the world better every day</p>
                                <p className="h5 mb-4 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.common.white, letterSpacing: 0.7}}>We invest in people, processes, and technology to drive growth and achieve success in today's competitive landscape.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.grey[300], padding: 0, minHeight: '400px', marginTop: '-10px', paddingTop: '24px', paddingBottom: '24px'}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className="row mt-4 mb-4 justify-content-md-center">
                                <div className="col col-12 col-sm-12 col-md-10 col-lg-7 col-xl-7 d-block d-lg-none">
                                    <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '40px'}}>
                                        <p className="h2 mb-0 font-weight-normal" style={{fontFamily: 'inherit', fontSize: '40px'}}>A letter from our CEO</p>
                                        <p className="h5 mb-4 font-weight-light" style={{fontFamily: 'inherit', marginTop: '24px'}}>Explore the unwavering dedication of our team to ensure that every individual, no matter where they are on our global platform, has access to cutting-edge technology that empowers them to advance and thrive.</p>
                                        <div style={{ textAlign: 'inherit' }}>
                                            <Button className="mb-4" variant="contained" style={{padding: '10px', fontSize: '18px', textTransform: 'none', width: '160px'}}>
                                                Read Letter
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-none d-lg-block">
                                    <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'right', verticalAlign: 'middle', padding: '10px', paddingTop: '80px'}}>
                                        <p className="h1 mb-4 font-weight-normal" style={{fontFamily: 'inherit', fontSize: '48px'}}>A letter from our CEO</p>
                                        <p className="h5 mb-4 font-weight-light" style={{fontFamily: 'inherit', marginTop: '24px'}}>Explore the unwavering dedication of our team to ensure that every individual, no matter where they are on our global platform, has access to cutting-edge technology that empowers them to advance and thrive.</p>
                                        <div style={{ textAlign: 'inherit' }}>
                                            <Button className="mb-4" variant="contained" style={{padding: '10px', fontSize: '18px', textTransform: 'none', width: '160px'}}>
                                                Read Letter
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 d-none d-lg-block">
                                    <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                        <div style={{ textAlign: 'center', padding: '0px', marginTop: '24px', marginBottom: '24px'}}>
                                            <Card variant="outlined" fullWidth sx={{ textTransform: 'none', padding: '0px', display: 'block', borderRadius: '0px', borderTop: 'none', borderLeft: 'none', borderRight: 'none', backgroundColor: theme.palette.grey[100], cursor: 'pointer'}}>
                                                <CardMedia
                                                    sx={{ height: 400, filter: 'grayscale(100%)', ":hover": { filter: 'grayscale(0%)'}}}
                                                    image={IMG_4}
                                                    title="George Davy"
                                                />
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.common.black, padding: 0}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className='font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '24px', paddingBottom: '24px', marginTop: '40px'}}>
                                <p className="h1 mb-4 font-weight-normal" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.common.white}}>What we do</p>
                                <p className="h5 mb-4 font-weight-light" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.common.white}}>We use expertise that’s been proven and tested around the globe to help you get ahead of challenges, sense opportunities sooner and outpace change.</p>
                            </div>
                            <div className='font-weight-light' style={{textAlign: 'center', verticalAlign: 'middle', padding: '10px', paddingTop: '24px', paddingBottom: '40px', marginTop: '-24px'}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <Card variant="outlined" fullWidth sx={{ textTransform: 'none', padding: '0px', display: 'block', borderRadius: '0px', borderTop: 'none', borderLeft: 'none', borderRight: 'none', backgroundColor: theme.palette.grey[100], minHeight: '470px'}}>
                                            <CardMedia
                                                sx={{ height: 240 }}
                                                image={IMG_1}
                                                title="Transform experiences"
                                            />
                                            <CardContent>
                                                <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px'}}>
                                                    <p className="h3 mb-4 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>Transform experiences</p>
                                                    <p className="h5 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>Reach new levels of convenience, elegance and beauty with hyper-personalized experiences that connect faster, generate growth and build brand loyalty.</p>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <Card variant="outlined" fullWidth sx={{ textTransform: 'none', padding: '0px', display: 'block', borderRadius: '0px', borderTop: 'none', borderLeft: 'none', borderRight: 'none', backgroundColor: theme.palette.grey[100], minHeight: '470px'}}>
                                            <CardMedia
                                                sx={{ height: 240 }}
                                                image={IMG_2}
                                                title="Reimagine processes"
                                            />
                                            <CardContent>
                                                <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px'}}>
                                                    <p className="h3 mb-4 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>Reimagine processes</p>
                                                    <p className="h5 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>Learn how process automation and technology can help your business act with the kind of insight, precision and speed needed in our fast-changing world.</p>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <Card variant="outlined" fullWidth sx={{ textTransform: 'none', padding: '0px', display: 'block', borderRadius: '0px', borderTop: 'none', borderLeft: 'none', borderRight: 'none', backgroundColor: theme.palette.grey[100], minHeight: '470px'}}>
                                            <CardMedia
                                                sx={{ height: 240 }}
                                                image={IMG_3}
                                                title="Modernize technology"
                                            />
                                            <CardContent>
                                                <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px'}}>
                                                    <p className="h3 mb-4 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>Modernize technology</p>
                                                    <p className="h5 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>Stay relevant today and ready for tomorrow with software built to learn and deploy massive processing power—all unlocked in the cloud to create value in every byte of data.</p>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.grey[600], padding: 0, background: 'radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 200%)'}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '60px', paddingBottom: '60px'}}>
                                <p className="h1 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.common.white, letterSpacing: 0.7}}>Elevate your business by transforming it into one that anticipates the optimal course of action and instinctively takes action in critical moments.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.common.white, padding: 0, backgroundImage: `url(${BG_2})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'revert', minHeight: '500px'}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '60px'}}>
                                <p className="h1 mb-2 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.common.white, letterSpacing: 0.7}}>Our culture inspires us to live our values</p>
                                <p className="h5 mb-4 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.common.white, letterSpacing: 0.7}}>Each member of our team takes responsibility for creating a culture that enables exceptional outcomes.</p>
                            </div>
                        </div>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className='mb-4 font-weight-light' style={{textAlign: 'center', verticalAlign: 'middle', padding: '10px', paddingTop: '24px', paddingBottom: '24px'}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Card variant="outlined" fullWidth sx={{ textTransform: 'none', padding: '16px', display: 'block', borderRadius: '0px', minHeight: '120px', backgroundColor: theme.palette.grey[100]}}>
                                            <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px'}}>
                                                <p className="h3 mb-2 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>What we value</p>
                                                <p className="h5 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>We help imagine, build and implement technologies to keep our clients constantly aware and responsive.</p>
                                            </div>
                                            {/* <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px' }}>
                                                <p className="h6 mb-2 font-weight-normal" style={{fontFamily: 'inherit', cursor: 'pointer'}}>Learn more <ArrowCircleRightOutlinedIcon color="info"/></p>
                                            </div> */}
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Card variant="outlined" fullWidth sx={{ textTransform: 'none', padding: '16px', display: 'block', borderRadius: '0px', minHeight: '120px', backgroundColor: theme.palette.grey[100]}}>
                                            <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px'}}>
                                                <p className="h3 mb-2 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>What we believe</p>
                                                <p className="h5 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>Our social purpose weaves environmental and social considerations into every element of our business model.</p>
                                            </div>
                                            {/* <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px' }}>
                                                <p className="h6 mb-2 font-weight-normal" style={{fontFamily: 'inherit', cursor: 'pointer'}}>Learn more <ArrowCircleRightOutlinedIcon color="info"/></p>
                                            </div> */}
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Card variant="outlined" fullWidth sx={{ textTransform: 'none', padding: '16px', display: 'block', borderRadius: '0px', minHeight: '120px', backgroundColor: theme.palette.grey[100]}}>
                                            <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px'}}>
                                                <p className="h3 mb-2 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>Who we are</p>
                                                <p className="h5 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>Every day, all around the world, our people engineer impact―with their clients, communities, colleagues and in their own lives.</p>
                                            </div>
                                            {/* <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px' }}>
                                                <p className="h6 mb-2 font-weight-normal" style={{fontFamily: 'inherit', cursor: 'pointer'}}>Learn more <ArrowCircleRightOutlinedIcon color="info"/></p>
                                            </div> */}
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Card variant="outlined" fullWidth sx={{ textTransform: 'none', padding: '16px', display: 'block', borderRadius: '0px', minHeight: '120px', backgroundColor: theme.palette.grey[100]}}>
                                            <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px'}}>
                                                <p className="h3 mb-2 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>How we behave</p>
                                                <p className="h5 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>We respect everyone’s unique voice and background because we know that diversity helps us thrive, and we benefit from having everyone at the table.</p>
                                            </div>
                                            {/* <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px' }}>
                                                <p className="h6 mb-2 font-weight-normal" style={{fontFamily: 'inherit', cursor: 'pointer'}}>Learn more <ArrowCircleRightOutlinedIcon color="info"/></p>
                                            </div> */}
                                        </Card>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.common.white, padding: 0}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className='font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '24px', paddingBottom: '24px', marginTop: '40px'}}>
                                <p className="h1 mb-2 font-weight-normal" style={{fontFamily: 'inherit', letterSpacing: 0.7}}>Connect with OPS51</p>
                                <p className="h5 mb-2 font-weight-light" style={{fontFamily: 'inherit', letterSpacing: 0.7}}>Let's connect and explore the possibilities.</p>
                            </div>
                            <div className='font-weight-light' style={{textAlign: 'center', verticalAlign: 'middle', padding: '10px', paddingTop: '24px', paddingBottom: '40px', marginTop: '-24px'}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Card variant="outlined" fullWidth sx={{ textTransform: 'none', padding: '10px', display: 'block', borderRadius: '0px', border: 'none', marginBottom: '-24px'}}>
                                            <CardContent>
                                                <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px'}}>
                                                    <p className="h3 mb-4 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main }}>Contact</p>
                                                    <p className="h5 mb-2 font-weight-light" style={{fontFamily: 'inherit'}}>Let's connect and explore the possibilities.</p>
                                                </div>
                                                <div className='mt-0 mb-0 font-weight-light' style={{ textAlign: 'left', padding: '8px' }}>
                                                    <p className="h5 mb-2 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.info.main, cursor: 'pointer'}}>Get in touch <ArrowCircleRightOutlinedIcon color="info"/></p>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default Company;