import React from "react";
import { Grid, Card, Button, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// import { useHistory } from 'react-router-dom';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

import BG_1 from '../../../../assets/img/0dz8cJGIR4-unsplash.jpg';

const Contact = () => {
    const theme = useTheme();
    // const history = useHistory();
    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.common.white, padding: 0, backgroundImage: `url(${BG_1})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundBlendMode: 'darken', backgroundRepeat: 'revert', minHeight: '500px', marginTop: '-10px'}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <Card variant="outlined" style={{textAlign: 'left', verticalAlign: 'middle', opacity: 0.7, padding: '24px', marginTop: '150px', maxWidth: '500px', borderWidth: '2px', borderColor: theme.palette.info.dark, borderRadius: '0px', borderLeft: '0px', borderRight: '0px', borderBottom: '0px'}}>
                                <p className="h5 mb-4 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.grey[900], letterSpacing: 0.7}}>OPS<sup>51</sup> Experience</p>
                                <p className="h1 mb-4 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.common.black, letterSpacing: 0.7}}>Contact Us</p>
                                <p className="h5 mb-4 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.common.black, letterSpacing: 0.7}}>We believe that those who challenge the status quo today will do remarkable things tomorrow.</p>
                            </Card>
                        </div>
                    </div>
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.common.white, padding: 0}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className='mt-4' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '24px', paddingBottom: '0px'}}>
                                <p className="h1 mb-4 font-weight-normal" style={{fontFamily: 'inherit', color: theme.palette.common.black, letterSpacing: 0.7}}>Connect with us</p>
                            </div>
                            <div className='mt-0 mb-0' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '0px'}}>
                                <p className="h5 mb-4 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.grey[900], letterSpacing: 0.7}}>Serving our clients, solving problems and enhancing human experiences motivate everything we do. If you're as passionate about the possibilities as we are, discover the best digital opportunities for your business.</p>
                                <p className="h5 mb-1 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.grey[900], letterSpacing: 0.7}}>- Follow us on Facebook, LinkedIn or Twitter.</p>
                                <p className="h5 mb-1 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.grey[900], letterSpacing: 0.7}}>- Explore the Latest News and learn how we elevate experiences.</p>
                                <p className="h5 mb-1 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.grey[900], letterSpacing: 0.7}}>- Search our locations below to find an office.</p>
                            </div>
                            <div className='mt-4 mb-0' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px'}}>
                                <Button variant="outlined" style={{ padding: '10px', paddingLeft: '16px', paddingRight: '16px', fontSize: '14px', marginTop: '-16px', marginBottom: '40px', textTransform: 'none'}} onClick={(e) => { 
                                    e.preventDefault();
                                }}>
                                    Contact our team&nbsp;<ArrowCircleRightOutlinedIcon fontSize="small"/>
                                </Button>
                            </div>
                            
                        </div>
                    </div>
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.grey[600], padding: 0}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '24px', paddingBottom: '24px', marginTop: '40px'}}>
                                <p className="h1 mb-3 font-weight-light" style={{fontFamily: 'inherit', letterSpacing: 0.7, color: theme.palette.common.white}}>Global Offices</p>
                                <p className="h5 mb-3 font-weight-light" style={{fontFamily: 'inherit', color: theme.palette.common.white, letterSpacing: 0.7}}>We work seamlessly together as one firm to serve our clients wherever they need us.</p>
                            </div>
                            <div style={{textAlign: 'center', verticalAlign: 'middle', padding: '10px', paddingTop: '24px', paddingBottom: '48px', marginTop: '-36px'}}>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center" style={{backgroundColor: theme.palette.common.white, padding: 0}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className='font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px', paddingTop: '24px', paddingBottom: '24px', marginTop: '40px'}}>
                                <p className="h1 mb-2 font-weight-normal" style={{fontFamily: 'inherit', letterSpacing: 0.7}}>Get answers to your questions</p>
                                <p className="h5 mb-2 font-weight-light" style={{fontFamily: 'inherit', letterSpacing: 0.7}}>Our clients turn to us to help them reimagine ways of working with technology.</p>
                            </div>
                            <div className='font-weight-light' style={{textAlign: 'center', verticalAlign: 'middle', padding: '10px', paddingTop: '24px', paddingBottom: '40px', marginTop: '-24px'}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <TextField id="inquiry-name" type="text" label="Name" variant="filled" placeholder="John Smith" fullWidth required/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <TextField id="inquiry-email" type="email" label="Email" variant="filled" placeholder="johnsmith@example.com" fullWidth required/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <TextField id="inquiry-organization" type="text" label="Organization" variant="filled" placeholder="Abc Company" fullWidth required/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <TextField id="inquiry-phone" type="tel" label="Contact Number" variant="filled" placeholder="1 800 123 4567" fullWidth required />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField id="inquiry-message" type="text" label="Message" variant="filled" placeholder="Your message..." fullWidth multiline rows={5}/>
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{ textAlign: 'left', padding: '10px' }}>
                                <Button className="mb-4" variant="contained" style={{ padding: '10px', paddingLeft: '16px', paddingRight: '16px', fontSize: '14px', marginTop: '-24px'}} onClick={(e) => { 
                                    e.preventDefault();
                                }}>
                                    Submit&nbsp;<ArrowCircleRightOutlinedIcon fontSize="small"/>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact;